<template>
  <div>
    <div class="upload-item">
      <div class="uploader">
        <el-upload
          :action="uploadAction"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          list-type="picture-card"
          :multiple="true"
          ref="upload"
        >
          <img
            class="upload-img"
            src="../../assets/images/College/upload-button-bg.png"
            alt=""
          />
          <div class="upload-text">点击上传</div>
        </el-upload>
      </div>
      <div class="buttons">
        <img src="../../assets/images/painter/upload-cancel.png" alt="" @click="cancel" />
        <img src="../../assets/images/painter/upload-submit.png" alt="" @click="submit" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      uploadAction: "",
      images: [],
    };
  },
  mounted() {
    this.uploadAction = axios.defaults.baseURL + "api/common/upload";
  },
  methods: {
    // 文件上传成功
    handleAvatarSuccess(res) {
      let { url } = res.data;
      this.images.push(url);
    },
    beforeAvatarUpload(file) {
      let result = new Promise((resolve, reject) => {
        let that = this;
        var reader = new FileReader();
        reader.onload = function (event) {
          var txt = event.target.result;
          var img = document.createElement("img");
          img.src = txt;
          img.onload = function () {
            let x = img.width / img.height;
            if (x >= 0.5 && x <= 1) {
              resolve(true);
            } else {
              that.$message({
                message: "请上传宽高比例为[0.4:1 => 1:1]以内的图片",
                type: "warning",
              });
              reject(false);
            }
          };
        };
        reader.readAsDataURL(file);
      });
      return result;
    },
    cancel() {
      this.$refs.upload.clearFiles();
      this.images = [];
    },
    submit() {
      if (!this.images.length) {
        return;
      }
      let data = {
        images: this.images,
      };
      this.$api.slideshow.add(data).then(() => {
        this.$refs.upload.clearFiles();
        this.images = [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.upload-item {
  background: url(../../assets/images/painter/upload-bg.png) no-repeat;
  background-size: 100% 100%;
  width: 50%;
  height: 30vw;
  margin: 0 auto;
  padding: 4vw;
  text-align: left;
  position: relative;
  .uploader {
    height: 30vw;
    overflow: auto;
    .upload-text {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 2vw;
      left: 0;
      width: 100%;
    }
  }

  .buttons {
    position: absolute;
    right: 0;
    bottom: 1.5vw;
    display: flex;
    img {
      width: 4vw;
      margin-right: 1vw;
      cursor: pointer;
    }
  }
}
</style>
